export default {
    // Quiz
    MAIN_NAVIGATION_ITEM_CLICKED: 'Main Navigation Item Clicked',
    QUIZ_STARTED: 'Quiz Started',
    RENEWAL_QUIZ_STARTED: 'Renewal Quiz Started',
    MODAL_OPENED: 'Modal Opened',
    QUIZ_BUTTON_CLICKED: 'Quiz Button Clicked',
    EMAIL_SUBMITTED: 'Email Submitted',
    QUIZ_COMPLETED: 'Quiz Completed',
    LANDING_PAGE_BUTTON_CLICKED: 'Landing Page Button Clicked',
    EXPERIMENT_VIEWED: 'Experiment Viewed',
    LEAD_FORM_VIEWED: 'Lead Form Viewed',
    GATE_TRIGGERED: 'Gate Triggered',
    // Portal
    PORTAL_BUTTON_CLICKED: 'Portal Button Clicked',
    // Chat
    CHAT_SENT: 'Chat Sent',
    PHOTO_SENT: 'Photo Sent',
    PHOTO_OPENED: 'Photo Opened',
    FILE_SENT: 'File Sent',
    FILE_OPENED: 'File Opened',
};
