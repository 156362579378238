'use client';

import { useEffect } from 'react';
import { TrackingData } from '@growthbook/growthbook-react';
import { Vertical } from 'src/constants/Verticals';
import { useAppContext } from 'src/context/app';
import { page, track } from './Analytics';
import EventNames from './EventNames';
import { EventProps } from './EventProps';

type TrackPageConfig = {
    disabled?: boolean;
};

export const useTrackPage = (
    name: string,
    properties?: EventProps,
    config?: TrackPageConfig,
) => {
    const { previousRoute } = useAppContext();

    useEffect(() => {
        if (!config?.disabled) {
            const eventProperties = {
                ...properties,
            };

            if (previousRoute) {
                eventProperties.referrer =
                    window.location.origin + previousRoute;
            }

            page(name, '', eventProperties);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config?.disabled]);
};

type TrackPageProps = {
    name: string;
    eventProps?: EventProps;
};

export const TrackPage = ({ name, eventProps }: TrackPageProps) => {
    useTrackPage(name, eventProps);

    return null;
};

export function GrowthbookTracking({ data }: { data: TrackingData[] }) {
    useEffect(() => {
        data.forEach(({ experiment, result }) => {
            track(EventNames.EXPERIMENT_VIEWED, {
                experimentId: experiment.key,
                variationId: result.key,
            });
        });
    }, [data]);

    return null;
}

/* Generic click tracking event */
export const trackClick = (eventName: string, eventProps: EventProps) => {
    track(eventName, eventProps);
};

export const trackStartQuiz = (
    vertical: Vertical,
    flow: string,
    properties?: EventProps,
) => {
    track(
        flow === 'initial'
            ? EventNames.QUIZ_STARTED
            : EventNames.RENEWAL_QUIZ_STARTED,
        {
            vertical: vertical,
            ...properties,
        },
    );
};

export const trackCompletedQuiz = (
    vertical: Vertical,
    properties?: EventProps,
) => {
    track(EventNames.QUIZ_COMPLETED, {
        vertical: vertical,
        ...properties,
    });
};

export const trackEmailSubmitted = (
    vertical: Vertical,
    properties?: EventProps,
) => {
    track(EventNames.EMAIL_SUBMITTED, {
        vertical: vertical,
        ...properties,
    });
};

export const trackGateTriggered = (
    vertical: Vertical,
    properties?: EventProps,
) => {
    track(EventNames.GATE_TRIGGERED, {
        vertical: vertical,
        ...properties,
    });
};
