import classNames from 'classnames';
import EventNames from 'src/analytics/EventNames';
import { trackClick } from 'src/analytics/Events';
import styles from './moshy-logo.module.scss';

type MoshyLogoProps = {
    variant?: string;
    link?: string;
    label?: string;
    className?: string;
};
const MoshyLogo = (props: MoshyLogoProps) => {
    const handleLogoClick = () => {
        trackClick(EventNames.MAIN_NAVIGATION_ITEM_CLICKED, { label: 'Logo' });
        window.location.href = '/';
    };

    return (
        <figure
            className={classNames(styles['logo'], props.className)}
            data-variant={props.variant}
        >
            {props.link ? (
                <a
                    href={props.link}
                    title={props.label}
                    onClick={handleLogoClick}
                >
                    <Logo />
                </a>
            ) : (
                <Logo />
            )}
        </figure>
    );
};

const Logo = () => {
    return (
        <svg
            width="490"
            height="186"
            viewBox="0 0 490 186"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 149.911V140.094C14.6685 140.094 16.0372 131.647 16.4245 122.037L19.9368 23.7408L9.58098 22.3716V12.555H55.3167L75.6409 81.22H76.0282L98.5216 12.555H142.889V22.3716L132.533 23.7408L134.108 138.725L144.464 140.094V149.911H92.4786V140.094L102.834 138.725L101.466 33.7383H101.078L69.5978 128.521H60.9982L29.5177 33.5574H29.1304L26.9869 95.5574C26.5995 105.167 25.8248 113.408 25.8248 122.037C25.8248 132.628 30.3183 140.094 42.2493 140.094V149.911H0Z"
                fill="#003D3B"
            />
            <path
                d="M173.362 102.817C173.362 129.296 176.693 142.832 188.418 142.832C200.142 142.832 203.474 129.296 203.474 102.817C203.474 76.3375 197.999 62.8009 188.418 62.8009C178.837 62.8009 173.362 75.7434 173.362 102.817ZM231.623 102.817C231.623 125.576 218.323 152.649 188.418 152.649C158.513 152.649 145.213 125.576 145.213 102.817C145.213 80.0575 158.513 52.9842 188.418 52.9842C218.323 52.9842 231.623 80.0575 231.623 102.817Z"
                fill="#003D3B"
            />
            <path
                d="M278.133 71.61C276.971 67.0892 275.215 62.775 272.865 62.775C266.228 62.775 260.34 68.0709 260.34 74.555C260.34 92.225 306.85 84.5525 306.85 119.479C306.85 142.445 290.038 152.649 269.508 152.649C254.658 152.649 236.865 149.523 236.865 134.023C236.865 126.764 239.603 120.693 248.202 120.693C257.783 120.693 262.664 126.971 262.664 135.987C262.664 140.301 265.014 142.858 269.301 142.858C280.251 142.858 288.076 139.913 288.076 129.709C288.076 113.047 241.565 117.929 241.565 83.8034C241.565 64.7642 259.746 53.01 278.908 53.01C289.651 53.01 301.582 56.9367 301.582 68.1225C301.582 74.7875 298.664 80.29 290.632 80.29C281.671 80.2642 279.321 75.95 278.133 71.61Z"
                fill="#003D3B"
            />
            <path
                d="M309.794 149.911V140.895L318.988 139.732V26.0916C318.988 22.5525 312.738 21.39 309.794 21.39V12.3741L344.58 0V68.4841L344.968 68.8717C351.036 60.2433 359.817 52.9841 370.173 52.9841C391.272 52.9841 400.259 66.7275 400.259 87.5233V139.707L409.452 140.869V149.885H365.473V140.869L374.666 139.707V89.28C374.666 82.7958 371.748 73.3925 359.016 73.3925C351.605 73.3925 344.554 80.6516 344.554 85.7666V139.732L353.748 140.895V149.911H309.794Z"
                fill="#003D3B"
            />
            <path
                d="M430.784 52.9842C439.383 78.0942 449.352 102.223 459.707 126.377C462.057 120.487 474.169 95.5833 474.169 91.2433C474.169 87.9108 461.851 78.4817 461.851 67.89C461.851 60.6309 466.732 52.9842 475.151 52.9842C485.506 52.9842 490 59.2617 490 69.6467C490 85.9217 477.294 112.633 465.363 135.573C460.663 144.408 439.771 186 417.871 186C407.309 186 401.653 180.704 401.653 172.463C401.653 165.592 406.353 158.332 415.134 158.332C424.508 158.332 429.208 166.78 431.352 166.78C435.071 166.78 445.814 152.649 445.22 151.487L429.57 118.911C421.538 102.429 414.721 85.1725 411.777 79.0759C410.201 75.7434 406.302 74.1675 402.79 73.9867V64.9708L430.784 52.9842Z"
                fill="#003D3B"
            />
        </svg>
    );
};

export default MoshyLogo;
